/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../mixins";

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.flex > * {
    flex: 1 1 auto;
    min-width: 0;
}

/* ----- Flex Container --------------------------------------------------------------------------------------------- */
.flex--inline {
    display: inline-flex
}

.flex--row {
    flex-direction: row
}

.flex--row-reverse {
    flex-direction: row-reverse
}

.flex--column {
    flex-direction: column
}

.flex--column-reverse {
    flex-direction: column-reverse
}

.flex--wrap {
    flex-wrap: wrap
}

.flex--wrap-reverse {
    flex-wrap: wrap-reverse
}

.flex--justify-start {
    justify-content: flex-start
}

.flex--justify-end {
    justify-content: flex-end
}

.flex--justify-stretch {
    justify-content: stretch
}

.flex--align-start {
    align-items: flex-start
}

.flex--align-end {
    align-items: flex-end
}

.flex--align-stretch {
    align-items: stretch
}

/* ----- Flex Child ------------------------------------------------------------------------------------------------- */
.flex-order-0 {
    order: 0
}

.flex-order-1 {
    order: 1
}

.flex-order-2 {
    order: 2
}

.flex-min-content {
    flex: 0 0 auto;
}

.flex-max-content {
    flex: 1 1 auto;
}