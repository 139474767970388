/*
Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential

*/

@import "../mixins";

*,
:before,
:after {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
}

html {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -webkit-text-size-adjust: none;
    user-select: none;
    touch-action: manipulation;
    width: 100%;
    height: 100%;
}

body {
    background-color: black;
    margin: 0;
    font: {
        family: "Trebuchet MS", Helvetica, sans-serif;
        size: dp(14);
    }
    color: #555;
    overscroll-behavior: none;
    overflow: hidden;
}

html.ios-status-bar {
    &,
    body {
        position: absolute;
        overflow: hidden;
    }

    &,
    body {
        bottom: -20px;
        //bottom: calc(0px - env(safe-area-inset-top));
    }

    body {
        &:after {
            content: "";
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 20px;
            height: env(safe-area-inset-top);
            background-color: rgba(0, 0, 0, 0.75);
            z-index: 99999;
        }

        main {
            padding-top: 20px;
            padding-top: env(safe-area-inset-top);
        }
    }
}

.animation-reduce {
    main.backdrop {
        display: none !important;
    }

    .animated {
        animation-duration: 0s !important;
        transition-duration: 0s !important;
        animation-iteration-count: 1;
    }

    .bubbles1,
    .bubbles2 {
        display: none !important;
    }
}

img {
    outline: none;
}

input,
select,
option,
textarea {
    font: inherit;
    color: inherit;
    outline: none;
    background-color: transparent;
}

option {
    color: black;
}

input::-ms-clear {
    display: none;
}

textarea,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

hr {
    border: none;
    border-bottom: dp(1) solid rgba(155, 155, 155, 0.15);
}

h1 {
    font-size: 200%;
    font-weight: normal;
}

h2 {
    font-size: 160%;
    font-weight: normal;
}

h3 {
    font-size: 130%;
    font-weight: normal;
}

h4 {
    font-size: 105%;
    font-weight: normal;
}

p,
h1,
h2,
h3,
h4 {
    margin: dp(4) 0;
}

ul {
    list-style-type: square;
    padding-left: dp(24);
}

li {
    margin: dp(8);
}

.text-left {
    text-align: left;
}


.text-right {
    text-align: right;
    width: 200px;
}

.logo {
    max-width: 100%;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-upper {
    text-transform: uppercase;
}

.text-bold {
    font-weight: bold;
}

.text-italic {
    font-style: italic;
}

.text-underline {
    text-decoration: underline;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.text-trim {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hidden {
    display: none !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.25;

    *,
    *:before,
    *:after {
        text-shadow: none !important;
        box-shadow: none !important;
    }
}

button {
    -webkit-appearance: none;
    background-color: inherit;
    border: inherit;
    color: inherit;
    font: inherit;
    line-height: inherit;
    outline: none;
}

.fa-spin {
    backface-visibility: hidden;
}

.animated {
    backface-visibility: hidden;
    animation-duration: 0.8s;
    animation-timing-function: ease;

    &.faster {
        animation-duration: 0.35s;
    }

    &.fast {
        animation-duration: 0.6s;
    }

    &.slow {
        animation-duration: 1.2s;
    }

    &.slower {
        animation-duration: 2s;
    }
}

.world-clock {
    width: 100%;
    background-color: rgba(256, 256, 256, 0.5);
    padding: 0 12pt;
    .container {
        margin: 0 auto;
        max-width: dp(1280);
        @media screen and (min-width: 1100px) {
            max-width: 85%;
        }
        p {
            padding-top: 10px;
            font-size: 90%;
            display: flex;
            justify-content: space-between;
            margin: 0;
            span {
                text-align: center;
                b {
                    padding: 5px;
                    border-radius: 5px;
                    border: 1px solid #B0B0B0;
                    background-color: #fff;
                    text-align: center;
                    font-weight: normal;
                }
                i {
                    padding: 0;
                    line-height: 20px;
                    display: table;
                    margin: 5px auto;
                    text-align: center;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 90%;
                }
            }
        }
    }

}

.countdown_bc {
    position: absolute;
    &.flex {
        > * {
            min-width: auto;
        }
    }

    @media screen and (max-width: 500px) {
        max-width: 90%;
        width: 100%;
    }

    &.cdown_topleft {
        top: 55pt;
         @media screen and (min-width: 360px) {
             top: 60pt;
         }
        left: 10pt;
    }
    &.cdown_topright {
        top: 55pt;
         @media screen and (min-width: 360px) {
             top: 60pt;
         }
        right: 10pt;
    }
    &.cdown_topcenter {
        top: 55pt;
         @media screen and (min-width: 360px) {
             top: 60pt;
         }
        left: 50%;
        transform: translateX(-50%);
    }

    &.cdown_midleft {
        top: 50%;
        left: 10pt;
        transform: translateY(-50%);
    }
    &.cdown_midright {
        top: 50%;
        right: 10pt;
        transform: translateY(-50%);
    }
    &.cdown_midcenter {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.cdown_bottomleft {
        bottom: 10pt;
        left: 10pt;
    }
    &.cdown_bottomright {
        bottom: 10pt;
        right: 10pt;
    }
    &.cdown_bottomcenter {
        bottom: 10pt;
        left: 50%;
        transform: translateX(-50%);
    }

    .countdown_clock {
        display: none;
        padding: 10pt;
        border-radius: 5pt;
        text-align: center;
        &.shadow {
            text-shadow: 1px 1px 2px #000000;
        }
        @media screen and (max-width: 500px) {
            font-size: 20px !important;
        }
    }

    .bc_button {
        display: none;
    }
}
